import { bind } from './lib/bind'
import Pikaday from "pikaday"

bind(document, 'change keyup', (e) => {
  const target = e.target
  const form = target.closest('.js-change-enabler-form')
  if (form) {
    const button = form.querySelector('.js-change-enabler-button')
    button.disabled = false
  }
})

bind(document, "ajax:beforeSend", (e) => {
  const element = e.target
  if (element.classList.contains("js-change-enabler-form")) {
    const button = element.querySelector(".js-change-enabler-button");
    button.value = "Updating…";
    button.disabled = true;
  }
});

bind(document, "ajax:success", (e) => {
  const element = e.target
  if (element.classList.contains("js-change-enabler-form")) {
    const [data] = e.detail;
    if (data.html) {
      element.innerHTML = data.html;

      bind(element, "change keyup", () => {
        const button = element.querySelector(".js-change-enabler-button");
        button.disabled = false;
      });

      Array.from(element.querySelectorAll(".js-date-picker")).forEach(
        (input) => {
          new Pikaday({ field: input });
        }
      );
    } else {
      const button = element.querySelector(".js-change-enabler-button");
      button.value = "Update";
      button.disabled = true;
    }
  }
});
