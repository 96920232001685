import Honeybadger from "@honeybadger-io/js"

const apiKey = document.querySelector('meta[name="VITE_HONEYBADGER_API_KEY"]')?.content

if(apiKey) {
  Honeybadger.configure({
    apiKey,
    environment: import.meta.env.PROD ? 'production' : 'development',
    revision: import.meta.env.HEROKU_SLUG_COMMIT,
  })
}

document.querySelectorAll('.js-boomtown').forEach(element => {
  element.addEventListener('click', e => {
    e.preventDefault()
    setTimeout(() => { throw new Error('boomtown') }, 0)
    alert('A JavaScript error is being thrown.')
  }, false)
})
