const inputs = Array.from(document.querySelectorAll('.radio-selection input[type=radio]'))

const updateRadioSelections = () => {
  inputs.forEach(input => {
    const els = input.closest('.radio-selection').querySelectorAll('.show-if-radio-selection-selected')

    if (input.checked) {
      Array.from(els).forEach(el => el.classList.remove('hidden'))
    } else {
      Array.from(els).forEach(el => el.classList.add('hidden'))
    }
  })
}

inputs.forEach(input => input.addEventListener('change', updateRadioSelections))

updateRadioSelections()
