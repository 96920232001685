Array.from(document.querySelectorAll('.js-table-matrix')).forEach(table => {
  const selectAll = table.querySelector('.js-select-all-rows')
  const setRowStates = function() {
    Array.from(table.querySelectorAll('tbody tr')).forEach(row => {
      const rowInput = row.querySelector('.js-row-toggle')

      if (rowInput.checked) {
        row.classList.add('table-matrix-row-enabled')
      } else {
        row.classList.remove('table-matrix-row-enabled')
      }

      Array.from(row.querySelectorAll('.js-row-option')).forEach(input => input.disabled = !rowInput.checked)
    })

    const available_rows = Array.from(table.querySelectorAll('.js-row-toggle'))
    const selected_rows = Array.from(table.querySelectorAll('.js-row-toggle:checked'))

    if (selected_rows.length == 0) {
      selectAll.checked = false
      selectAll.indeterminate = false
    } else if (available_rows.length == selected_rows.length) {
      selectAll.checked = true
      selectAll.indeterminate = false
    } else {
      selectAll.checked = false
      selectAll.indeterminate = true
    }

    Array.from(table.querySelectorAll('.js-select-all-column')).forEach(optionSelectAll => {
      const option = optionSelectAll.dataset.option

      if (selected_rows.length == 0) {
        optionSelectAll.checked = false
        optionSelectAll.indeterminate = false
        optionSelectAll.disabled = true
      } else {
        const available_option_rows = Array.from(table.querySelectorAll(`.js-row-option[data-option="${option}"]:not(:disabled)`))
        const selected_option_rows = Array.from(table.querySelectorAll(`.js-row-option[data-option="${option}"]:checked`))

        if (selected_option_rows.length == 0) {
          optionSelectAll.checked = false
          optionSelectAll.indeterminate = false
          optionSelectAll.disabled = false
        } else if (available_option_rows.length == selected_option_rows.length) {
          optionSelectAll.checked = true
          optionSelectAll.indeterminate = false
          optionSelectAll.disabled = false
        } else {
          optionSelectAll.checked = false
          optionSelectAll.indeterminate = true
          optionSelectAll.disabled = false
        }
      }
    })
  }

  const toggleAllRows = function() {
    const status = selectAll.checked
    Array.from(table.querySelectorAll('.js-row-toggle')).forEach(input => {
      input.checked = status
    })

    setRowStates()
  }

  const toggleColumn = function(e) {
    const status = e.target.checked
    const option = e.target.dataset.option

    table.querySelectorAll(`.js-row-option[data-option="${option}"]`).forEach(input => {
      if (input.disabled == false) {
        input.checked = status
      }
    })
  }

  Array.from(document.querySelectorAll('.js-row-toggle, .js-row-option')).forEach(input => {
    input.addEventListener('change', setRowStates)
  })

  Array.from(document.querySelectorAll('.js-select-all-column')).forEach(input => {
    input.addEventListener('change', toggleColumn)
  })

  selectAll.addEventListener('change', toggleAllRows)

  setRowStates()
})
