document.addEventListener("DOMContentLoaded", function () {
  const timeStatusRadios = document.querySelectorAll(
    'input[name="event[time_status]"]'
  );
  const startsAtInput = document.querySelector(
    'input[name="event[starts_at]"]'
  );

  function storeCurrentValueForStartsAt() {
    const currentValue = startsAtInput.value;
    startsAtInput.dataset.previousValue = currentValue;
  }

  function updateStartsAtInput() {
    storeCurrentValueForStartsAt();
    const selectedRadio = document.querySelector(
      'input[name="event[time_status]"]:checked'
    );

    if (selectedRadio) {
      const includeTime = selectedRadio.dataset.includeTime === "true";
      const previousValue = startsAtInput.dataset.previousValue;
      const currentValue = startsAtInput.value;

      if (includeTime) {
        // Convert date to datetime-local format if necessary
        if (startsAtInput.type === "date") {
          startsAtInput.type = "datetime-local";
          if (currentValue) {
            startsAtInput.value = new Date(previousValue)
              .toISOString()
              .slice(0, 16);
          }
        }
      } else {
        // Convert datetime-local to date format if necessary
        if (startsAtInput.type === "datetime-local") {
          startsAtInput.type = "date";
          
          if (currentValue) {
            startsAtInput.value = previousValue.split("T")[0];
          }
        }
      }

      storeCurrentValueForStartsAt();
    }
  }

  startsAtInput?.addEventListener("change", function () {
    startsAtInput.dataset.previousValue = startsAtInput.value;
  });

  timeStatusRadios.forEach((radio) => {
    radio.addEventListener("change", updateStartsAtInput);
  });

  // Initial update on page load
  if (startsAtInput) updateStartsAtInput()
});
