import {bind} from './lib/bind'

bind(document, 'click', (e) => {
  if (e.target.closest('.js-toggle-trigger')) {
    const container = e.target.closest('.js-toggle-container')
    container.classList.toggle('toggle-on')

    if (container.classList.contains('toggle-on')) {
      const focusElement = container.querySelector('.js-toggle-focus-when-on')
      if (focusElement) {
        focusElement.focus()
      }
    }
  }

  if (e.target.closest('.js-menu-trigger')) {
    document.body.classList.toggle('menu-open')
  }
})
