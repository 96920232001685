import {bind} from './lib/bind'

let interval

const createSpinner = () => {
  const spinner = document.createElement('DIV')
  spinner.classList.add('spinner')
  spinner.classList.add('spinner-sm')
  spinner.classList.add('mt-n1')

  return spinner
}

const updateServers = () => {
  Array.from(document.querySelectorAll('.js-server-update-indicator')).forEach((element) => {
    element.appendChild(createSpinner())
  })

  fetch('/admin/servers.json').then((response) => {
    return response.json()
  }).then((data) => {
    document.querySelector('.js-servers-list').outerHTML = data.servers
  })
}

const startPoll = () => {
  interval = setInterval(updateServers, 15000)
}

if (document.querySelector('.js-servers-list')) {
  startPoll()
}

bind(document, 'click', (e) => {
  if (e.target.closest('.js-server-reboot')) {
    e.preventDefault()

    const shouldRestart = confirm('Are you sure you want to reboot this server? It will be down for ~20 seconds.')

    if (shouldRestart) {
      const button = e.target.closest('.js-server-reboot')
      const url = button.href
      const parent = button.parentNode
      const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

      button.remove()
      parent.appendChild(createSpinner())

      clearInterval(interval)

      fetch(url, {
        method: 'post',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': token
        },
        credentials: 'same-origin',
      }).then(() => {
        startPoll()
      })
    }
  }
})
