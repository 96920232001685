import {bind} from './lib/bind'
import {debounce} from './lib/debounce'

const show = function(el) {
  if (el) el.style.display = 'block'
}

const hide = function(el) {
  if (el) el.style.display = 'none'
}

const performSearch = function(input) {
  let showSearching
  const url = input.dataset.searchUrl
  const query = input.value
  const container = input.closest('.js-search')
  const results = container.querySelector('.js-search-results')
  const searching = container.querySelector('.js-searching')
  const browseContainer = container.querySelector('.js-browse-container')
  const searchContainer = container.querySelector('.js-search-container')

  if (query.length > 0) {
    showSearching = function() {
      hide(results)
      show(searching)

      hide(browseContainer)
      show(searchContainer)
    };

    const timeout = setTimeout(showSearching, 400);

    fetch(`${url}?query=${encodeURIComponent(query)}`).then(function(response) {
      return response.text()
    }).then(function(body) {
      clearTimeout(timeout);
      results.innerHTML = body
      show(results)
      hide(searching)

      hide(browseContainer)
      show(searchContainer)

      window.BSN.initCallback()
    })
  } else {
    hide(searchContainer);
    show(browseContainer);
  }
};

const debouncedPerformSearch = debounce(performSearch, 300);

bind('.js-search-input', 'keydown', function(e) {
  const element = e.target
  if ((element.value === '') || (e.keyCode === 27)) { //esc
    element.value = ''
    performSearch(e.target)
  } else {
    debouncedPerformSearch(e.target)
  }
});

Array.from(document.querySelectorAll('.js-search-input')).forEach((input) => {
  performSearch(input)
})
