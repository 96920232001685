import axios from 'redaxios'
import Sortable from 'sortablejs'

Array.from(document.querySelectorAll(".js-sortable")).forEach((element) => {
  const url = element.dataset.sortableUrl

  if (!url) {
    console.error("Missing sortable-url data attribute", element)
    return
  }

  Sortable.create(element, {
    direction: 'vertical',
    handle: ".sortable-handle",
    onEnd: function(event) {
      let id = event.item.dataset.id

      if (!id) {
        console.error("Missing data-id attribute", event.item)
        return
      }

      let data = new FormData()
      data.append("id", id)
      data.append("position", event.newIndex + 1)

      axios({
        method: "POST",
        url: url,
        data: data,
        headers: {
          'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content,
        }
      }).catch(function(error) {
        console.log('catch', error.toJSON())
        alert(error)
      })
    }
  })
})
