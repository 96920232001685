import { Dropdown as LegacyDropdown } from './lib/dropdown'
import { bind } from './lib/bind'
import { Tooltip, Popover, Dropdown, Alert } from "bootstrap";

Array.from(document.querySelectorAll('.js-dropdown-toggle')).forEach((element) => {
  new LegacyDropdown(element);
})

Array.from(document.querySelectorAll('.alert .close')).forEach((element) => {
  bind(element, 'click', function() {
    const alert = element.closest('.alert')
    alert.parentNode.removeChild(alert)
  })
})

const plugins = {
  tooltip: Tooltip,
  popover: Popover,
  dropdown: Dropdown,
};

const selector = Object.keys(plugins)
  .map((key) => `[data-bs-toggle=${key}]`)
  .join(", ");

function initializeBootstrap(container = document) {
  container.querySelectorAll(selector).forEach((el) => {
    const Plugin = plugins[el.dataset.bsToggle];
    if (Plugin) new Plugin(el);
  });

  const alertList = document.querySelectorAll(".alert");
  [...alertList].map((element) => new Alert(element));
}

//  Initialize on page load
document.addEventListener("DOMContentLoaded", () => initializeBootstrap());

// Observe DOM for new elements and initialize them
const observer = new MutationObserver((mutationsList) => {
  for (const mutation of mutationsList) {
    if (mutation.type === "childList") {
      [...mutation.addedNodes].forEach((addedNode) => {
        if (addedNode instanceof HTMLElement) {
          initializeBootstrap(addedNode);
        }
      });
    }
  }
});

observer.observe(document, { childList: true, subtree: true });
