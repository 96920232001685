import { bind } from './lib/bind'

bind('.js-partner-select', 'change', function (e) {
  const hometownPartnerId = 2;
  let partnerHometownSelected = e.target.value == hometownPartnerId;

  if (partnerHometownSelected) {
    document.getElementById('admin-org-edit-hometown-org-form-group').classList.remove('d-none');
  } else {
    document.getElementById('admin-org-edit-hometown-org-form-group').classList.add('d-none');
  }
})
