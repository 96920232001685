export const bind = function(elements, events, fn) {
  events = events.split(' ')

  if (typeof(elements) == 'string') {
    elements = Array.from(document.querySelectorAll(elements))
  }

  if (!Array.isArray(elements)) {
    elements = Array.from([elements])
  }

  elements.forEach((element) => {
    events.forEach((event) => {
      if (element.addEventListener) {
        element.addEventListener(event, fn, false);
      } else if (element.attachEvent) {
        element.attachEvent(`on${event}`, fn);
      } else {
        element[`on${event}`] = fn;
      }
    })
  })
};

export const unbind = function(elements, events, fn) {
  events = events.split(' ')

  if (typeof(elements) == 'string') {
    elements = Array.from(document.querySelectorAll(elements))
  }

  if (!Array.isArray(elements)) {
    elements = Array.from([elements])
  }

  elements.forEach((element) => {
    events.forEach((event) => {
      if (element.addEventListener) {
        element.removeEventListener(event, fn, false);
      } else if (element.attachEvent) {
        element.detachEvent(`on${event}`, fn);
      } else {
        element[`on${event}`] = null;
      }
    })
  })
};
