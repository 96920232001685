import { bind } from './lib/bind'

bind(document, 'click', (e) => {
  if (e.target.closest('[data-dismiss="dismissable"]')) {
    const container = e.target.closest('.js-dismissable')
    container.style.height = `${container.offsetHeight}px`
    container.classList.add('dismissed')

    if (container.dataset.dismissUrl) {
      fetch(container.dataset.dismissUrl, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
    }
  }
})
