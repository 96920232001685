import {bind} from './bind'

export class Dropdown {
  constructor(element) {
    this.shown = false
    this.element = element
    this.positioner = element.closest('.dropdown')
    this.direction = element.dataset.direction
    this.offsetX = parseInt(element.dataset.offsetX) || 0
    this.offsetY = parseInt(element.dataset.offsetY) || 0

    this.menu = document.body.querySelector(`[aria-labelledby=${element.id}]`)

    this.showMenu = this.showMenu.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.hideMenu = this.hideMenu.bind(this)

    bind(document.body, 'click', this.handleClick)
  }

  showMenu() {
    let top, left, bottom, right;
    const dimensions = this.positioner.getBoundingClientRect()
    const relativeDimensions = this.menu.offsetParent.getBoundingClientRect()

    switch (this.direction) {
      case 'bottom-right':
        right = relativeDimensions.width - dimensions.width + this.offsetX
        top = this.positioner.offsetX + dimensions.height + this.offsetY
        this.menu.style.bottom = 'auto'
        this.menu.style.left = 'auto'
        this.menu.style.top = `${top}px`
        this.menu.style.right = `${right}px`
        break
      case 'top-left':
        bottom = (relativeDimensions.height - dimensions.top) + this.offsetY
        left = dimensions.left + this.offsetX

        this.menu.style.top = 'auto'
        this.menu.style.right = 'auto'
        this.menu.style.bottom = `${bottom}px`
        this.menu.style.left = `${left}px`
        break
      default:
        top = dimensions.bottom + this.offsetY
        left = dimensions.left + this.offsetX

        this.menu.style.top = `${top}px`
        this.menu.style.left = `${left}px`
        break
    }

    this.positioner.classList.add('show')
    this.menu.classList.add('show')
    this.shown = true

    const firstInput = this.menu.querySelector('input')
    if (firstInput) {
      firstInput.focus()
    }
  }

  handleClick(e) {
    if (this.shown && e.target.closest('.dropdown-menu') != this.menu) {
      this.hideMenu()
    } else {
      if (e.target.closest('.js-dropdown-toggle') == this.element) {
        this.showMenu()
      }
    }
  }

  hideMenu() {
    this.positioner.classList.remove('show')
    this.menu.classList.remove('show')
    this.shown = false
  }
}
