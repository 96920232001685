Array.from(document.querySelectorAll('[data-requires-input]')).forEach(select => {
  const inputTeamTypes = select.dataset.requiresInput.split(',')
  const inputTeamTypesEl = select.closest('.js-requires-input').querySelector('.js-conditional-input')
  const teamNameEl = inputTeamTypesEl.closest(".form-group");

  const checkInput = () => {
    const teamType = select.options[select.selectedIndex].value
    if (inputTeamTypes.indexOf(teamType) !== -1) {
      inputTeamTypesEl.required = true
      teamNameEl.classList.remove('hidden')
    } else {
      inputTeamTypesEl.required = false
      teamNameEl.classList.add('hidden')
    }
  }

  select.addEventListener('change', checkInput)
  checkInput()
})
