const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

const defaultTransition = "all 500ms ease-in-out"
const defaultRootMargin = "0% 0% -10% 0%"
const defaultThreshold = 0.5

const transitionMaker = (transition, element) => {
  if (transition.ioTransition != "none") {
    transition.ioTransition ? element.style.transition = transition.ioTransition : null
    transition.ioTransitionDuration ? element.style.transitionDuration = transition.ioTransitionDuration : null
    transition.ioTransitionTiming ? element.style.transitionTimingFunction = transition.ioTransitionTiming : null
    transition.ioTransitionDelay ? element.style.transitionDelay = transition.ioTransitionDelay : null
    transition.ioTransitionProperty ? element.style.transitionProperty = transition.ioTransitionProperty : null
  }
}

// Converts a string of styles into an object
const styleMaker = (styleString, element) => {
  // Remove the trailing ;
  const cleanedStyleString = styleString.trim().replace(/;$/, '');
  const styleObject = cleanedStyleString.split(";");

  
  styleObject.forEach((style) => {
    const styleArray = style.split(":");
    const property = styleArray[0].trim();
    const value = styleArray[1].trim();
    
    // Check if the property is a CSS Custom Property
    if (property.startsWith('--')) {
      // If it is, use the property as is
      element.style.setProperty(property, value);
    } else {
      // If it's not, convert the property to camelCase
      const camelCaseProperty = property.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
      element.style[camelCaseProperty] = value;
    }
  })
}

// Converts a string of classes into an array of quoted classes
const classSplicer = (list) => {
  // Split the list into an array of items
  const items = list.split(' ');

  // Join them with commas
  const newList = items.join(', ');

  return newList;
}

if ('IntersectionObserver' in window && !prefersReducedMotion.matches) {
  const ioElements = document.querySelectorAll("[data-io='true']")

  // Get the options for the IntersectionObserver
  const getOptions = (el) => {
    return {
      rootMargin: el.getAttribute('data-io-root') || defaultRootMargin,
      threshold: parseFloat(el.getAttribute('data-io-threshold')) || defaultThreshold // parse to ensure it's a number
    }
  }

  // Get the details for the element
  const getDetails = (el) => {
    const defaultStyle = (value) => el.getAttribute("data-io-transition") === "none" ? null : value
    return {
      "ioFromClass": el.getAttribute("data-io-from-class") || null,
      "ioToClass": el.getAttribute("data-io-class") || el.getAttribute("data-io-to-class") || null,
      "ioFrom": el.getAttribute("data-io-from") || defaultStyle("opacity: 0"),
      "ioTo": el.getAttribute("data-io-to") || defaultStyle("opacity: 1"),
      "ioTransitionData": {
        "ioTransition": el.getAttribute("data-io-transition") || defaultTransition,
        "ioTransitionDuration": el.getAttribute("data-io-duration") || null,
        "ioTransitionTiming": el.getAttribute("data-io-timing") || null,
        "ioTransitionDelay": el.getAttribute("data-io-delay") || null,
        "ioTransitionProperty": el.getAttribute("data-io-property") || null
      }
    }
  }

  // Loop through the elements and create the IntersectionObserver
  ioElements.forEach((el) => {
    const ioDetails = getDetails(el)

    // Apply from classes 
    if (ioDetails.ioFromClass) {
      el.classList.add(classSplicer(ioDetails.ioFromClass))
      transitionMaker(ioDetails.ioTransitionData, el)
    }

    // Apply from styles
    if (ioDetails.ioFrom) {
      styleMaker(ioDetails.ioFrom, el)
      transitionMaker(ioDetails.ioTransitionData, el)
    }
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        
        if (entry.isIntersecting) {                
          // Remove any from classes
          if (ioDetails.ioFromClass) {
            entry.target.classList.remove(classSplicer(ioDetails.ioFromClass))
            transitionMaker(ioDetails.ioTransitionData, entry.target)
          }

          // Apply to classes
          if (ioDetails.ioToClass) {
            entry.target.classList.add(classSplicer(ioDetails.ioToClass))
            transitionMaker(ioDetails.ioTransitionData, entry.target)
          }

          // Apply to styles
          if (ioDetails.ioTo) {
            styleMaker(ioDetails.ioTo, entry.target)
            transitionMaker(ioDetails.ioTransitionData, entry.target)
          }
          observer.unobserve(entry.target)
        }
      })
    }, getOptions(el))

    observer.observe(el)
  })
}
